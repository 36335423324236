<template>
  <div class="contract-plan-list">
    <PageTitle title="合約方案列表" hideBtn />
    <section>
      <BaseTable v-loading="loading" :data="contractPlanList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="合約方案" prop="name" align="center" fixed="left" />
        <BaseElTableColumn label="店家綁定上限" prop="shopLimit" align="center" />
        <BaseElTableColumn label="已綁定店家數" prop="shopCount" align="center" />
        <BaseElTableColumn label="包含模組" prop="modules" align="center" width="300" />
        <BaseElTableColumn label="合約效期" prop="contractPeriod" align="center" />
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="contractCount"
        @pageChange="changePage"
      />
    </section>
  </div>
</template>

<script>
import { moduleText } from '@/config/contractPlan'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetContractPlan, GetContractPlanCount } from '@/api/contract'
import { get } from 'lodash'
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import { formatDate } from '@/utils/date'

export default defineComponent( {
  name: 'ContractPlanList',
  components: { EmptyBlock },
  setup () {
    const loading = ref(false)
    const selectRow = ref(null)
    const tableOptions = reactive({
      page: 1,
      pageLimit: 10,
    })
    const contractPlanList = ref([])
    const contractCount = ref(0)
    const pageStart = computed(() => pageStartIndex(tableOptions.page, tableOptions.pageLimit))

    //= > 取得合約
    const getContractPlan = async () => {
      try {
        contractPlanList.value = (await GetContractPlan({ start: pageStart.value, limit: tableOptions.pageLimit })).map(one => ({
          ...one,
          contractPeriod: `${formatDate(get(one.Contract, 'start'), 'YYYY/MM/DD')} 至 ${formatDate(get(one.Contract, 'end'), 'YYYY/MM/DD')}`,
          modules: Object.keys(one).map(key => {
            if (one[key] === true && moduleText[key]) {
              return moduleText[key]
            }
            return null
          }).filter(one => one !== null).join('、'),
        }))
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }
    //= > 取得總店家數量
    const getContractPlanCount = async () => {
      try {
        contractCount.value = await GetContractPlanCount({})
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }
    const changePage = async () => {
      await refresh()
    }
    const refresh = async () => {
      await Promise.all([getContractPlan(), getContractPlanCount()])
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      loading,
      selectRow,
      tableOptions,
      contractPlanList,
      contractCount,
      changePage,
    }
  },
})
</script>

<style scoped lang="scss"></style>
